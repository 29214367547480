import React from "react";
import * as Tooltip from "@radix-ui/react-tooltip";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";

interface TooltipProps {
  text: string;
}

const InfoTooltip: React.FC<TooltipProps> = ({ text }) => {
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <button className="p-1 rounded-full border-gray-300">
          <QuestionMarkCircledIcon className="w-5 h-5 text-gray-500" />
        </button>
      </Tooltip.Trigger>
      <Tooltip.Content
        side="bottom"
        className="max-w-xs p-2 bg-black text-white rounded-md z-20"
      >
        {text}
      </Tooltip.Content>
    </Tooltip.Root>
  );
};

export default InfoTooltip;
