import "./App.css";
import "@radix-ui/themes/styles.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import SplashPage from "./pages/Splash";
import ConsolePage from "./pages/Console";
import AuthPage from "./pages/Auth";
import RoutePage from "./pages/Route";

function App() {
  return (
    <Router>
      <div className="App">
        <div className="w-screen h-screen">
          <Routes>
            <Route path="/" element={<SplashPage />} />
            <Route path="/builder" element={<ConsolePage />} />
            <Route path="/auth" element={<AuthPage />} />
            <Route path="/routes/:routeId" element={<RoutePage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
