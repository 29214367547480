import React, { ReactNode, useEffect } from "react";
import { useCurrentUser } from "../api";
import { useNavigate } from "react-router-dom";

interface SubtitleProps {
  children: React.ReactNode;
}
const Subtitle: React.FC<SubtitleProps> = ({ children }) => (
  <h3 className="text-primary mb-2 text-4xl">{children}</h3>
);

interface TitleProps {
  children: string;
}
const Title: React.FC<TitleProps> = ({ children }) => {
  const words = children.split(" ");
  const lastWord = words.pop();
  return (
    <h2 className="text-white mb-4 text-[4em]">
      {words.join(" ")} <span className="text-primary">{lastWord}</span>
    </h2>
  );
};

interface BodyTextProps {
  children: React.ReactNode;
}
const BodyText: React.FC<BodyTextProps> = ({ children }) => (
  <p className="text-lg">{children}</p>
);

interface TwoColumnSectionProps {
  leftColumn: React.ReactNode;
  rightColumn: React.ReactNode;
}
const TwoColumnSection: React.FC<TwoColumnSectionProps> = ({
  leftColumn,
  rightColumn,
}) => (
  <div className="flex flex-col md:flex-row w-full px-16">
    <div className="flex-1 p-4 md:p-8">{leftColumn}</div>
    <div className="flex-1 p-4 md:p-8">{rightColumn}</div>
  </div>
);

interface GettingStartedButtonProps {
  href?: string;
}

const GettingStartedButton: React.FC<GettingStartedButtonProps> = ({
  href = "/auth",
}) => {
  return (
    <a
      href={href}
      className="uppercase inline-block text-xl hover:text-primary border-2 border-primary text-white px-24 py-6 rounded-full hover:scale-105 hover:text-white transition-all duration-100 transform"
    >
      Start Building
    </a>
  );
};

const Header: React.FC = () => {
  return (
    <div className="fixed top-0 left-0 w-full z-10">{/* Header content */}</div>
  );
};

interface SectionProps {
  style?: React.CSSProperties;
  children?: ReactNode;
  isMobile?: boolean;
}

const Section: React.FC<SectionProps> = ({ style, children, isMobile }) => {
  const visibilityClass = isMobile
    ? "flex md:hidden items-center justify-center"
    : "flex items-center justify-center";

  return (
    <div style={style} className={`${visibilityClass}`}>
      {children}
    </div>
  );
};

const FooterLink: React.FC<{
  href: string;
  children: string;
  newTab?: boolean;
}> = ({ href, children, newTab }) => {
  return (
    <a
      target={newTab ? "_blank" : ""}
      href={href}
      className="text-white text-gray-200 hover:text-gray-300"
    >
      {children}
    </a>
  );
};

const FooterHeader: React.FC<{ children: string }> = ({ children }) => (
  <h5 className="text-white font-semibold">{children}</h5>
);

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-slate-900 text-white p-10">
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
        <div>
          <FooterLink href="https://pintales.io">Pintales</FooterLink>
          <p className="text-gray-100 mt-8">
            Copyright © {currentYear} Thirty Report Inc.
          </p>
        </div>

        <div>
          <FooterHeader>Product</FooterHeader>
          <ul className="mt-4 space-y-2">
            <li>
              <FooterLink href="/builder">Builder</FooterLink>
            </li>
            <li>
              <FooterLink href="/auth">Sign Up</FooterLink>
            </li>
          </ul>
        </div>

        <div>
          <FooterHeader>Company</FooterHeader>
          <ul className="mt-4 space-y-2">
            <li>
              <FooterLink href="mailto:support@pintales.io">Contact</FooterLink>
            </li>
          </ul>
        </div>

        <div>
          <FooterHeader>Legal</FooterHeader>
          <ul className="mt-4 space-y-2">
            <li>
              <FooterLink
                newTab={true}
                href="https://app.getterms.io/view/4l4ak/tos/en-us"
              >
                Terms of Service
              </FooterLink>
            </li>
            <li>
              <FooterLink
                newTab={true}
                href="https://app.getterms.io/view/4l4ak/privacy/en-us"
              >
                Privacy Policy
              </FooterLink>
            </li>
            <li>
              <FooterLink
                newTab={true}
                href="https://app.getterms.io/view/4l4ak/aup/en-us"
              >
                Acceptable Use Policy
              </FooterLink>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

const AppleDownloadButton: React.FC = () => (
  <a
    href="https://apps.apple.com/ca/app/thirty-trail/id6444912396"
    target="_blank"
    rel="noopener noreferrer"
    className="flex items-center"
  >
    <img
      src="/apple-app-store-badge.svg"
      alt="Download on the Apple Store"
      className="h-12 md:h-14 lg:h-16"
    />
  </a>
);

const AndroidDownloadButton: React.FC = () => (
  <a
    href="https://play.google.com/store/apps/details?id=com.thirtyreportinc.thirtytrail"
    target="_blank"
    rel="noopener noreferrer"
    className="flex items-center"
  >
    <img
      src="/google-play-badge.png"
      alt="Get it on Google Play"
      className="h-12 md:h-14 lg:h-16"
    />
  </a>
);

const getOS = () => {
  const userAgent = window.navigator.userAgent;
  if (
    /iPad|iPhone|iPod/.test(userAgent) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  ) {
    return "iOS";
  } else if (/Android/.test(userAgent)) {
    return "Android";
  }
  return "other";
};

const DownloadAppButton: React.FC = () => {
  const os = getOS();
  return (
    <div className="mt-4 flex flex-row items-center w-full space-x-4">
      {os === "iOS" && <AppleDownloadButton />}
      {os === "Android" && <AndroidDownloadButton />}
      {os === "other" && (
        <>
          <AppleDownloadButton />
          <AndroidDownloadButton />
        </>
      )}
    </div>
  );
};

const Section1: React.FC = () => {
  return (
    <Section
      style={{
        backgroundImage:
          "linear-gradient(45deg, rgba(57,67,69,0.58) 0%, rgba(77,62,54,0.6) 100%), url(/bg_s1.jpeg)",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="flex flex-col lg:flex-row h-full p-4 md:p-20">
        <div className="flex-1 flex flex-col justify-center items-start p-8 lg:p-16">
          <div className="text-primary md:text-2xl text-xl">
            CURATED, SELF-GUIDED NATURE HIKES AND URBAN TOURS.
          </div>
          <div className="text-white md:text-[6em] text-[3em] font-bold">
            Pintales
          </div>
          <div className="md:text-2xl text-lg leading-8 mt-2 mb-12">
            {`Explore the world around you with Pintales. Our app offers a way to learn about nature and history through well-crafted, self-guided tours created by knowledgeable enthusiasts. Whether you're venturing into the natural world or delving into the rich stories of urban landscapes, Pintales provides a platform for immersive, and educational journeys. Download now to start exploring meaningful trails and tours at your own pace.`}
          </div>
          <DownloadAppButton />
        </div>
        <div className="flex-1 flex flex-col items-center justify-center lg:flex hidden">
          <img
            src="/screenshot_s1.png"
            alt="App Screenshot"
            className="mb-8 max-w-full max-h-[70vh] h-auto"
          />
        </div>
      </div>
    </Section>
  );
};

const Section1Screenshot: React.FC = () => {
  return (
    <Section
      isMobile={true}
      style={{
        maxHeight: "100vh",
        paddingTop: "32px",
        paddingBottom: "32px",
        boxSizing: "border-box",
      }}
    >
      <div className="flex flex-col items-center justify-center h-full">
        <img
          src="/screenshot_s1.png"
          alt="App Screenshot"
          className="max-w-full max-h-full h-auto"
        />
      </div>
    </Section>
  );
};

const Section2: React.FC = () => {
  // const leftColumnContent = (
  //   <>
  //     <Subtitle>Subtitle</Subtitle>
  //     <Title>Your Adventure Title</Title>
  //     <BodyText>
  //       Explore the world around you with self-guided tours...
  //     </BodyText>
  //   </>
  // );

  // const rightColumnContent = (
  //   <>
  //     <Subtitle>Subtitle</Subtitle>
  //     <Title>Another Journey Title</Title>
  //     <BodyText>
  //       Discover hidden gems and historical sites on your next walk...
  //     </BodyText>
  //   </>
  // );

  // return (
  //   <Section style={{ backgroundColor: "black" }}>
  //     <TwoColumnSection
  //       leftColumn={leftColumnContent}
  //       rightColumn={rightColumnContent}
  //     />
  //   </Section>
  // );

  return (
    <Section style={{ backgroundColor: "#020617" }}>
      <div className="flex flex-col lg:flex-row h-full p-4 md:p-20">
        <div className="flex-1 flex flex-col justify-center items-start p-8 lg:p-16">
          <div className="text-primary text-xl md:text-2xl">
            DESIGN WITH EASE
          </div>
          <div className="text-white text-[2em] md:text-[4em] font-bold">
            Want to add your own trails or tours to Pintales?
          </div>
          <div className="text-lg md:text-2xl leading-8 mt-2 mb-12">
            {`Unlock the power to bring trails and tours to life with Pintales. Our platform empowers creators like you to design interactive, educational, and unforgettable journeys for explorers everywhere. Whether it’s a nature walk rich with ecological wonders or an urban exploration steeped in history, Pintales connects your narrative with adventurers eager for discovery.`}{" "}
          </div>
          <GettingStartedButton />
        </div>
        <div className="flex-1 flex flex-col items-center justify-center lg:flex hidden"></div>
      </div>
    </Section>
  );
};

const Section3: React.FC = () => {
  return (
    <Section
      style={{
        height: "calc(100vh * 2/3)",
        backgroundImage:
          "linear-gradient(45deg, rgba(57,67,69,0.58) 0%, rgba(77,62,54,0.6) 100%), url(https://pintales.carrd.co/assets/images/container16.jpg?v=c97cd4c5)",
        backgroundAttachment: "fixed",
        backgroundPosition:
          "0% 0%, 50% calc(50% - (((var(--scroll-y, 0) * 1px) - (var(--element-top, 0) * 1px)) * var(--background-parallax-intensity, 0)) )",
        backgroundRepeat: "repeat, no-repeat",
        backgroundSize: "cover, cover",
      }}
    />
  );
};

const Section4: React.FC = () => {
  return <Section style={{ backgroundColor: "black" }} />;
};

const Section5: React.FC = () => {
  return (
    <Section
      style={{
        height: "calc(100vh * 2/3)",
        backgroundImage:
          "linear-gradient(45deg, rgba(57,67,69,0.58) 0%, rgba(77,62,54,0.6) 100%), url(https://pintales.carrd.co/assets/images/container02.jpg?v=c97cd4c5)",
        backgroundAttachment: "fixed",
        backgroundPosition:
          "0% 0%, 50% calc(50% - (((var(--scroll-y, 0) * 1px) - (var(--element-top, 0) * 1px)) * var(--background-parallax-intensity, 0)) )",
        backgroundRepeat: "repeat, no-repeat",
        backgroundSize: "cover, cover",
      }}
    />
  );
};

const useCheckLoggedIn = () => {
  const user = useCurrentUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      navigate("/builder");
    }
  }, [user, navigate]);
  return user;
};

const Section6: React.FC = () => {
  return <Section style={{ backgroundColor: "black" }} />;
};

const Splash: React.FC = () => {
  useCheckLoggedIn();
  return (
    <div className="text-[rgba(255,255,255,0.741)]">
      <Header />
      <main>
        <Section1 />
        {/* <Section1Screenshot /> */}
        <Section2 />
        {/* <Section3 />
        <Section4 />
        <Section5 />
        <Section6 /> */}
      </main>
      <Footer />
    </div>
  );
};

export default Splash;
