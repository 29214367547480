import React, { useState } from "react";
import Spinner from "./Spinner";

interface ButtonProps {
  type: "primary" | "danger";
  children: React.ReactNode;
  spinnerLocation?: "side" | "cover";
  hasBackground?: boolean;
  onClick: () => Promise<any>;
}

const Button: React.FC<ButtonProps> = ({
  type,
  children,
  onClick,
  spinnerLocation = "side",
  hasBackground = true,
}) => {
  const [busy, setBusy] = useState(false);

  const handleClick = async () => {
    try {
      setBusy(true);
      await onClick();
    } finally {
      setBusy(false);
    }
  };

  const backgroundColor =
    type === "primary"
      ? "bg-blue-500 hover:opacity-90"
      : "bg-red-500 hover:opacity-90";
  const busyBackgroundColor = "bg-gray-400";

  const activeBackgroundColor = busy ? busyBackgroundColor : backgroundColor;

  return (
    <button
      onClick={handleClick}
      disabled={busy}
      className={`${
        hasBackground ? activeBackgroundColor : null
      } text-white p-2 rounded flex flex-row items-center space-x-2`}
    >
      {spinnerLocation == "side" ? (
        <>
          {busy ? <Spinner size="md" theme="light" /> : null}
          {children}
        </>
      ) : (
        <>{busy ? <Spinner size="md" theme="light" /> : children}</>
      )}
    </button>
  );
};

export default Button;
