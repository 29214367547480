import React, { useState } from "react";
import { Button, TextArea } from "@radix-ui/themes";
import * as Dialog from "@radix-ui/react-dialog";
import { IStopMedia, updateMediaCaption } from "../api";

interface EditMediaDialogProps {
  open: boolean;
  media: IStopMedia;
  onClose: () => void;
}

function TextInput({
  defaultValue,
  placeholder,
  onValueChange,
}: {
  placeholder?: string;
  defaultValue?: string;
  onValueChange: (value: string) => void;
}) {
  return (
    <div className="w-full">
      <TextArea
        size="3"
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={(event: any) => onValueChange(event.nativeEvent.target.value)}
      />
    </div>
  );
}

export const EditMediaDialog: React.FC<EditMediaDialogProps> = ({
  open,
  media,
  onClose,
}) => {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <Dialog.Overlay
        className="fixed inset-0 bg-black bg-opacity-30"
        style={{ marginLeft: 0 }}
      />
      <Dialog.Content className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg w-1/2 max-w-md">
        <img
          src={
            media.thumbnails?.find((thumbnail) => thumbnail.size === "original")
              ?.url
          }
          alt="Editable"
          className="w-full h-64 object-cover rounded-t-lg"
        />
        <div className="p-4">
          <TextInput
            placeholder="Image caption"
            defaultValue={media.caption}
            onValueChange={(value) => {
              updateMediaCaption(media.id, value);
            }}
          ></TextInput>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};
