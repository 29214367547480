import React, { useState } from "react";
import Spinner from "./Spinner"; // Replace with your Spinner component
import { TrashIcon } from "@radix-ui/react-icons";

interface IconButtonProps {
  type?: "delete";
  onClick: () => Promise<any>;
}

const IconButton: React.FC<IconButtonProps> = ({
  type = "delete",
  onClick,
}) => {
  const [busy, setBusy] = useState(false);

  const handleClick = async () => {
    try {
      setBusy(true);
      await onClick();
    } finally {
      setBusy(false);
    }
  };

  const iconColor = busy ? "text-gray-400" : "text-red-500";
  const borderColor = busy ? "border-gray-400" : "border-red-500";

  const renderIcon = () => {
    if (type === "delete") {
      return <TrashIcon className="w-6 h-6" />;
    }
    return null;
  };

  return (
    <div>
      {busy ? (
        <div className={`p-2 flex items-center justify-center`}>
          {" "}
          <Spinner size="md" theme="light" />
        </div>
      ) : (
        <button
          onClick={handleClick}
          disabled={busy}
          className={`p-2 rounded-full border ${borderColor} ${iconColor} flex items-center justify-center`}
        >
          {renderIcon()}
        </button>
      )}
    </div>
  );
};

export default IconButton;
