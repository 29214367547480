import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Map, MapMarker } from "../components/Map";
import {
  IStop,
  usePublicStopPreviewMedia,
  usePublicStops,
  useRoute,
} from "../api";

interface RouteProps {
  routeId?: string;
}

interface StopMarkersProps {
  stops?: Array<IStop>;
  activeId?: string;
  onSelect: (stop: IStop) => void;
}

interface StopScrollViewProps {
  stops?: Array<IStop>;
  activeId?: string;
  onSelect: (stop: IStop) => void;
}

interface StopCardProps {
  stop: IStop;
  onSelect: (stop: IStop) => void;
}

const StopMarkers: React.FC<StopMarkersProps> = ({
  activeId,
  stops,
  onSelect,
}) => {
  if (!stops) {
    return null;
  }
  return (
    <>
      {stops.map(
        (stop: IStop) =>
          stop.latitude &&
          stop.longitude && (
            <MapMarker
              key={stop.id}
              color={activeId == stop.id ? "rgb(216,80,64)" : "teal"}
              onClick={() => onSelect(stop)}
              lngLat={{ lng: stop.longitude, lat: stop.latitude }}
            />
          )
      )}
    </>
  );
};

const StopCard: React.FC<StopCardProps> = ({ stop, onSelect }) => {
  const media = usePublicStopPreviewMedia(stop.id);
  const preview = media && media.length > 0 ? media[0] : null;
  const imageUrl = preview
    ? preview.thumbnails?.find((thumbnail) => thumbnail.size === "small")?.url
    : null;

  return (
    <div
      className="flex-none w-[300px] bg-gray-50 rounded-lg overflow-hidden shadow hover:opacity-90 cursor-pointer transition-all"
      onClick={() => onSelect(stop)}
    >
      <div className="relative pb-[56.25%]">
        {" "}
        {/* 16:9 Aspect Ratio */}
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="Stop"
            className="absolute inset-0 w-full h-full object-cover"
          />
        ) : (
          <div className="absolute inset-0 bg-gray-400 flex justify-center items-center">
            Image Placeholder
          </div>
        )}
        <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-50"></div>
        <div className="absolute bottom-0 p-4 w-full text-white font-semibold">
          {stop.title}
        </div>
      </div>
    </div>
  );
};

const StopScrollView: React.FC<StopScrollViewProps> = ({ stops, onSelect }) => {
  if (!stops) return null;
  return (
    <div className="absolute bottom-0 left-0 right-0 flex p-4 space-x-4  shadow-lg overflow-x-auto">
      {stops.map((stop) => (
        <StopCard key={stop.id} stop={stop} onSelect={onSelect} />
      ))}
    </div>
  );
};

const Content: React.FC<{ routeId: string }> = ({ routeId }) => {
  const route = useRoute(routeId);
  const stops = usePublicStops(routeId);
  const [activeStop, setActiveStop] = useState<IStop | undefined>();

  const handleStopClick = (stop: IStop) => {
    setActiveStop(stop);
  };

  if (!route) {
    return <NotFound />;
  }

  console.log("active", activeStop);

  return (
    <div className="relative w-full h-full">
      <Map>
        <StopMarkers
          activeId={activeStop?.id}
          stops={stops}
          onSelect={handleStopClick}
        />
        <StopScrollView
          activeId={activeStop?.id}
          stops={stops}
          onSelect={handleStopClick}
        />
      </Map>
    </div>
  );
};

const NotFound: React.FC = () => {
  return <div>Not Found</div>;
};

const RoutePage: React.FC = () => {
  const { routeId } = useParams();

  return (
    <div className="w-full h-full">
      {routeId ? <Content routeId={routeId} /> : <NotFound />}
    </div>
  );
};

export default RoutePage;
