import {
  IStop,
  IStopMedia,
  addStopMedia,
  deleteStopMedia,
  updateMediaIndex,
  updateStopLocation,
  useStopMedia,
  useStop,
  updateStopTitle,
  updateStopDescription,
  deleteStop,
  updateStopIcon,
  updateStopAlertRadius,
  resetStopIcon,
  updateStopLatitude,
  updateStopLongitude,
} from "../api";
import { useRef, useState } from "react";
import { Button, Text, TextArea, TextField } from "@radix-ui/themes";
import { DragAndDropList, Orientation } from "../components/DragAndDropList";
import Dropzone from "../components/Dropzone";
import { useElementSize } from "../hooks/useElementSize";
import { addClickHandler, LngLat } from "../components/EditorMap";
import { useToast } from "../components/Toast";
import { useConfirmationDialog } from "../components/ConfirmationDialog";
import { EditMediaDialog } from "../components/EditMediaDialog";
import BusyButton from "../components/BusyButton";
import BusyIconButton from "../components/BusyIconButton";

function TextInput({
  defaultValue,
  placeholder,
  onValueChange,
}: {
  placeholder?: string;
  defaultValue?: string;
  onValueChange: (value: string) => void;
}) {
  return (
    <div className="h-[200px]">
      <TextArea
        size="3"
        className="h-full"
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={(event: any) => onValueChange(event.nativeEvent.target.value)}
      />
    </div>
  );
}

function TitleInput({ stop }: { stop: IStop }) {
  const { showToast } = useToast();
  return (
    <label>
      <Text>Title</Text>
      <TextField.Root className="mt-1">
        <TextField.Input
          onChange={async (event: any) => {
            updateStopTitle(stop.id, event.nativeEvent.target.value).catch(
              (e) => showToast("An error occurred updating the stop title.")
            );
          }}
          placeholder="title"
          defaultValue={stop.title}
        />
      </TextField.Root>
    </label>
  );
}

function LocationInput({ stop }: { stop: IStop }) {
  const [waitForClick, setWaitForClick] = useState(false);
  const { showToast } = useToast();
  const handleMapClick = ({ lngLat }: { lngLat: LngLat }) => {
    setWaitForClick(false);
    updateStopLocation(stop.id, lngLat.lat, lngLat.lng).catch((e) =>
      showToast("An error occurred updating the stop location.")
    );
  };
  return (
    <div className="flex flex-row space-x-4">
      <label>
        <Text>Latitude</Text>
        <TextField.Root className="mt-1">
          <TextField.Input
            type="number"
            placeholder="latitude"
            key={stop.latitude}
            defaultValue={stop.latitude}
            onChange={(event: any) => {
              const latitude = parseFloat(event.nativeEvent.target.value);
              const longitude = stop.longitude;
              if (latitude && !isNaN(latitude)) {
                updateStopLatitude(stop.id, latitude);
              }
            }}
          />
        </TextField.Root>
      </label>
      <label>
        <Text>Longitude</Text>
        <TextField.Root className="mt-1">
          <TextField.Input
            type="number"
            placeholder="longitude"
            key={stop.longitude}
            defaultValue={stop.longitude}
            onChange={(event: any) => {
              const longitude = parseFloat(event.nativeEvent.target.value);
              if (longitude && !isNaN(longitude)) {
                updateStopLongitude(stop.id, longitude);
              }
            }}
          />
        </TextField.Root>
      </label>
      <div className="flex flex-col justify-end">
        <Button
          disabled={waitForClick}
          onClick={() => {
            setWaitForClick(true);
            addClickHandler(handleMapClick);
          }}
        >
          Select Location
        </Button>
      </div>
    </div>
  );
}

function AlertRadiusInput({ stop }: { stop: IStop }) {
  return (
    <div className="flex flex-row space-x-4">
      <label>
        <Text>Alert Radius (m)</Text>
        <TextField.Root className="mt-1">
          <TextField.Input
            type="number"
            placeholder="alert radius"
            key={stop.alertRadius}
            defaultValue={stop.alertRadius}
            onChange={(event: any) => {
              const alertRadius = parseFloat(event.nativeEvent.target.value);
              if (alertRadius && !isNaN(alertRadius)) {
                updateStopAlertRadius(stop.id, alertRadius);
              }
            }}
          />
        </TextField.Root>
      </label>
    </div>
  );
}

function DescriptionInput({ stop }: { stop: IStop }) {
  const { showToast } = useToast();
  return (
    <label className="flex flex-col flex-1">
      <Text>Description</Text>
      <TextInput
        onValueChange={(value: string) => {
          updateStopDescription(stop.id, value).catch((e) =>
            showToast("An error occurred updating the stop description.")
          );
        }}
        placeholder="description"
        defaultValue={stop.description}
      ></TextInput>
    </label>
  );
}

function TrashIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-6 h-6"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
      />
    </svg>
  );
}

interface ImageMediaProps {
  src: string;
  style?: React.CSSProperties;
  onLoad: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
}

const ImageMedia: React.FC<ImageMediaProps> = ({ src, style, onLoad }) => {
  const imageRef = useRef<HTMLImageElement>(null);

  return (
    <img
      ref={imageRef}
      style={style}
      className="h-full"
      onLoad={onLoad}
      src={src}
      alt=""
    />
  );
};

interface AudioMediaProps {
  src: string;
  type: string;
}

const AudioMedia: React.FC<AudioMediaProps> = ({ src, type }) => {
  return (
    <audio controls className="w-[200px] pr-8">
      <source src={src} type={type} />
      Your browser does not support the audio element.
    </audio>
  );
};

interface MediaProps {
  media: IStopMedia;
  onDelete: () => void;
  onClick: () => void;
}

const Media: React.FC<MediaProps> = ({ media, onDelete, onClick }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);

  const isImage = media.contentType.startsWith("image/");
  const isAudio = media.contentType.startsWith("audio/");

  const mediaUrl = isImage
    ? media.thumbnails?.find((thumbnail) => thumbnail.size === "original")
        ?.url || media.thumbnails?.[0]?.url
    : media.url;

  return (
    <div
      className={`h-full relative rounded-lg overflow-hidden cursor-${
        isDragging ? "grabbing" : "grab"
      }`}
      ref={containerRef}
      onMouseDown={() => {
        setIsDragging(true);
        window.addEventListener("mouseup", () => setIsDragging(false), {
          once: true,
        });
      }}
      onClick={onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {isImage && <ImageMedia src={mediaUrl!} onLoad={() => {}} />}
      {isAudio && <AudioMedia src={mediaUrl!} type={media.contentType} />}

      {isImage ? (
        <div
          className={`absolute inset-0 bg-gradient-to-b from-[rgba(0,0,0,0.10)] via-[rgba(0,0,0,0.02)] to-[rgba(0,0,0,0)] ${
            !isHover ? "opacity-0" : "opacity-100"
          } transition-all duration-300`}
        ></div>
      ) : null}

      <div className="p-2 cursor-pointer absolute top-0 right-0">
        <TrashIcon
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onDelete();
          }}
          className={`w-6 h-6 ${
            isImage ? "text-white" : "text-black"
          } hover:opacity-80 transition-all duration-300 ${
            !isHover ? "opacity-0" : "opacity-100"
          }`}
        ></TrashIcon>
      </div>
    </div>
  );
};

function StopMedia({ stop }: { stop: IStop }) {
  const [editMedia, setEditMedia] = useState<IStopMedia>();
  const stopMedia = useStopMedia(stop.id) || [];
  const handleDelete = (mediaId: string) => {
    deleteStopMedia(mediaId);
  };
  const renderMedia = (mediaId: string) => {
    const media = stopMedia.find((media) => media.id === mediaId);
    return media ? (
      <Media
        onClick={() => {
          setEditMedia(media);
        }}
        onDelete={() => handleDelete(mediaId)}
        media={media}
      ></Media>
    ) : null;
  };
  const ids = stopMedia.map((media) => media.id);
  const handleOrderChange = (ids: Array<string>) => {
    ids.forEach((id, index) => {
      updateMediaIndex(id, index);
    });
  };

  return ids && ids.length > 0 ? (
    <div className="w-full h-full flex flex-row space-x-4">
      <DragAndDropList
        key={ids.length}
        orientation={Orientation.HORIZONTAL}
        defaultValue={ids}
        renderItem={renderMedia}
        onOrderChange={handleOrderChange}
      ></DragAndDropList>
      {editMedia ? (
        <EditMediaDialog
          onClose={() => {
            setEditMedia(undefined);
          }}
          open={true}
          media={editMedia}
        />
      ) : null}
    </div>
  ) : null;
}

function MediaUpload({ stop }: { stop: IStop }) {
  const { showToast } = useToast();
  const handleUpload = (url: string) => {
    return addStopMedia(stop.id, url)
      .then(() => console.log("upload success"))
      .catch((error) => {
        showToast("An error occurred uploading media.");
      });
  };
  return (
    <div className="w-full h-full">
      <Dropzone onMedia={handleUpload} mediaTypes={["image", "audio"]} />
    </div>
  );
}

function StopIcon({ stop }: { stop: IStop }) {
  const [isHover, setIsHover] = useState(false);
  const handleResetStopIcon = async () => {
    return resetStopIcon(stop.id);
  };
  return (
    <div
      className="relative"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <img className="w-[150px] h-[150px]" src={stop.icon}></img>
      <div
        className={`p-2 cursor-pointer absolute top-0 right-0 ${
          !isHover ? "opacity-0" : "opacity-100"
        }`}
      >
        <BusyButton
          spinnerLocation="cover"
          hasBackground={false}
          onClick={handleResetStopIcon}
          type={"primary"}
        >
          <TrashIcon
            className={
              "w-6 h-6 text-white hover:opacity-80 transition-all duration-300"
            }
          ></TrashIcon>
        </BusyButton>
      </div>
    </div>
  );
}

function StopIconUpload({ stop }: { stop: IStop }) {
  const { showToast } = useToast();
  const handleUpload = (url: string) => {
    return updateStopIcon(stop.id, url)
      .then(() => console.log("upload success"))
      .catch((error) => {
        showToast("An error occurred uploading the icon.");
      });
  };
  return (
    <div>
      <Text>Stop Icon</Text>
      <div className="flex flex-row items-center space-x-4">
        <div className="w-[150px] h-[150px]">
          <Dropzone onMedia={handleUpload} mediaTypes={["image"]} />
        </div>
        {stop.icon ? <StopIcon stop={stop}></StopIcon> : null}
      </div>
    </div>
  );
}

function Footer({ stop }: { stop: IStop }) {
  return (
    <div>
      <Text>Stop Images</Text>
      <div className="flex flex-row h-[200px] space-x-4 w-full">
        <div className="w-1/5">
          <MediaUpload stop={stop} />
        </div>
        <div className="w-4/5 flex flex-row overflow-x-scroll">
          <StopMedia stop={stop} />
        </div>
      </div>
    </div>
  );
}

export default function StopDetails({
  onDelete,
  stopId,
}: {
  onDelete: () => void;
  stopId: string;
}) {
  const stop = useStop(stopId);
  const { openDialog } = useConfirmationDialog();
  const handleDelete = async () => {
    return new Promise((resolve, reject) => {
      openDialog({
        title: "Delete Route",
        message:
          "Do you want to delete this stop? This action is permanent and cannot be reversed.",
        buttons: [
          {
            type: "cancel",
            label: "Cancel",
            onClick: () => {
              resolve("cancelled");
            },
          },
          {
            type: "danger",
            label: "Delete",
            onClick: async (event) => {
              try {
                onDelete();
                await deleteStop(stopId);
                resolve("deleted");
              } catch (error) {
                reject(error);
              }
            },
          },
        ],
      });
    });
  };
  return stop ? (
    <div className="p-4 flex w-full h-full flex-col space-y-8 relative">
      <div className="flex flex-row space-x-4">
        <div className="w-full">
          <TitleInput stop={stop} />
        </div>
        <div className="flex flex-row w-full justify-end items-center">
          <BusyIconButton type="delete" onClick={() => handleDelete()} />
        </div>
      </div>
      <StopIconUpload stop={stop} />
      <AlertRadiusInput stop={stop} />
      <LocationInput stop={stop} />
      <DescriptionInput stop={stop} />
      <Footer stop={stop}></Footer>
    </div>
  ) : null;
}
