import { RefObject, useCallback, useEffect, useState } from "react";

interface Size {
  width: number;
  height: number;
}

export function useElementSize(
  ref: RefObject<HTMLDivElement>
): Size | undefined {
  const [size, setSize] = useState<Size>();
  useEffect(() => {
    if (!ref.current) return;
    const el = ref.current;
    const resizeObserver = new ResizeObserver(() => {
      setSize({ width: el.offsetWidth, height: el.offsetHeight });
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect();
  }, []);
  return size;
}
