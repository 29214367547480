import { useRef } from "react";
import { useElementSize } from "../hooks/useElementSize";
import { Card } from "@radix-ui/themes";
import { IRoute, IStop, useStops } from "../api";
import Mapbox from "../components/EditorMap";

export default function MapSection({
  route,
  onSelectStop,
}: {
  route: IRoute;
  onSelectStop: (stopId: string) => void;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const size = useElementSize(containerRef);
  const stops = useStops(route.id);
  const markers =
    stops
      ?.filter((stop) => stop.latitude && stop.longitude)
      .map((stop) => {
        return {
          id: stop.id,
          lngLat: { lng: stop.longitude!, lat: stop.latitude! },
          icon: stop.icon,
          radius: stop.alertRadius || 25,
        };
      }) || [];
  return (
    <div
      style={{ minHeight: 250, height: "35vh" }}
      ref={containerRef}
      className={`w-full p-2`}
    >
      {size ? (
        <Card className={`w-full h-full`}>
          <Mapbox
            key={route.id}
            markers={markers}
            onClickMarker={(marker) => {
              onSelectStop(marker.id);
            }}
          ></Mapbox>
        </Card>
      ) : null}
    </div>
  );
}
