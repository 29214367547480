import { ReactNode, useCallback } from "react";
import { Select, Switch, Text, TextArea, TextField } from "@radix-ui/themes";
import {
  IRoute,
  RouteType,
  deleteRoute,
  updateRouteLive,
  updateRouteOverview,
  updateRouteTitle,
  updateRouteType,
  updateRouteVisibility,
  useRoute,
  getRouteUsageData,
} from "../api";
import { useToast } from "../components/Toast";
import { useConfirmationDialog } from "../components/ConfirmationDialog";
import BusyIconButton from "../components/BusyIconButton";
import InfoTooltip from "../components/InfoTooltip";
import UsageChart from "../components/UsageChart";

function Section({
  title,
  required,
  children,
  tooltip,
}: {
  title: string;
  required: boolean;
  children?: ReactNode;
  tooltip: string;
}) {
  return (
    <div>
      <div className="flex flex-row items-center">
        <Text>{title + (required ? " (Required)" : "")}</Text>
        <InfoTooltip text={tooltip} />
      </div>
      <div className="mt-2">{children}</div>
    </div>
  );
}

function TextInput({
  defaultValue,
  placeholder,
  onValueChange,
}: {
  placeholder?: string;
  defaultValue?: string;
  onValueChange: (value: string) => void;
}) {
  return (
    <div className="h-[200px]">
      <TextArea
        size="3"
        className="h-full"
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={(event: any) => onValueChange(event.nativeEvent.target.value)}
      />
    </div>
  );
}

function LiveSwitch({ route }: { route: IRoute }) {
  const { showToast } = useToast();
  return (
    <div>
      <label>
        <div className="flex flex-row items-center">
          Live
          <InfoTooltip text="Toggle the status to indicate if the route is currently active and available for users in the app. 'Live' means users can access and follow this route." />
        </div>
        <div className="h-[32px] mt-1 flex flex-row items-center">
          <Switch
            onCheckedChange={(live) => {
              updateRouteLive(route.id, live).catch(() =>
                showToast("An error occurred updating the live status.")
              );
            }}
            defaultChecked={route.live}
          />
        </div>
      </label>
    </div>
  );
}

function TitleInput({ route }: { route: IRoute }) {
  const { showToast } = useToast();
  return (
    <label>
      <div className="flex flex-row items-center">
        Route Title
        <InfoTooltip text="Enter the name of your route. This title will be displayed as the main identifier for your route across the app." />
      </div>
      <TextField.Root className="mt-1">
        <TextField.Input
          onChange={(event: any) => {
            updateRouteTitle(route.id, event.nativeEvent.target.value).catch(
              () => showToast("An error occurred updating the title.")
            );
          }}
          placeholder="title"
          defaultValue={route.title}
        />
      </TextField.Root>
    </label>
  );
}

function RouteTypeSelect({ route }: { route: IRoute }) {
  const { showToast } = useToast();
  return (
    <div>
      <div className="flex flex-row items-center">
        Route Type
        <InfoTooltip text="Select the type of route. Choose 'Trail' for outdoor, nature paths and 'Tour' for city or cultural routes." />
      </div>
      <div className="mt-1">
        <Select.Root
          defaultValue={route.type}
          onValueChange={(type) => {
            const routeType =
              type === "trail" ? RouteType.Trail : RouteType.Tour;
            updateRouteType(route.id, routeType).catch(() =>
              showToast("An error occurred updating the route type.")
            );
          }}
        >
          <Select.Trigger
            className="w-[150px]"
            placeholder="Select a route type..."
          />
          <Select.Content>
            <Select.Group>
              <Select.Label>Route Type</Select.Label>
              <Select.Item value="trail">Trail</Select.Item>
              <Select.Item value="tour">Tour</Select.Item>
            </Select.Group>
          </Select.Content>
        </Select.Root>
      </div>
    </div>
  );
}

interface RouteVisibilitySelectProps {
  route: IRoute;
}

const RouteVisibilitySelect: React.FC<RouteVisibilitySelectProps> = ({
  route,
}) => {
  const { showToast } = useToast();

  const handleVisibilityChange = async (visibility: string) => {
    try {
      await updateRouteVisibility(route.id, visibility);
    } catch (error) {
      showToast("An error occurred updating the route visibility.");
    }
  };

  return (
    <div>
      <div className="flex flex-row items-center">
        Route Visibility
        <InfoTooltip text="Set the route's visibility. Choose 'Public' to make it visible to all users, or 'Hidden' to restrict access." />
      </div>
      <div className="mt-1">
        <Select.Root
          defaultValue={route.visibility}
          onValueChange={handleVisibilityChange}
        >
          <Select.Trigger
            className="w-[150px]"
            placeholder="Select visibility..."
          ></Select.Trigger>
          <Select.Content>
            <Select.Group>
              <Select.Label>Visibility</Select.Label>
              <Select.Item value="hidden">Hidden</Select.Item>
              <Select.Item value="public">Public</Select.Item>
            </Select.Group>
          </Select.Content>
        </Select.Root>
      </div>
    </div>
  );
};

export default function RouteDetails({
  onDelete,
  routeId,
}: {
  onDelete: () => void;
  routeId: string;
}) {
  const route = useRoute(routeId);
  const { showToast } = useToast();
  const { openDialog } = useConfirmationDialog();
  const handleDelete = async () => {
    return new Promise((resolve, reject) => {
      openDialog({
        title: "Delete Route",
        message:
          "Do you want to delete this route? This action is permanent and cannot be reversed.",
        buttons: [
          {
            type: "cancel",
            label: "Cancel",
            onClick: () => {
              resolve("cancelled");
            },
          },
          {
            type: "danger",
            label: "Delete",
            onClick: async (event) => {
              try {
                onDelete();
                await deleteRoute(routeId);
                resolve("deleted");
              } catch (error) {
                reject(error);
              }
            },
          },
        ],
      });
    });
  };

  return route ? (
    <div className="p-4 flex flex-col space-y-8">
      <div className="flex flex-row space-x-4">
        <div className="w-full">
          <TitleInput route={route} />
        </div>
        <RouteTypeSelect route={route} />
        <RouteVisibilitySelect route={route} />
        <LiveSwitch route={route} />
        <div className="flex flex-row w-full justify-end items-center">
          <BusyIconButton type="delete" onClick={() => handleDelete()} />
        </div>
      </div>
      <Section
        title="Overview"
        required={true}
        tooltip="Provide a brief description of the route. This overview will help users understand what to expect and what makes this route unique."
      >
        <TextInput
          onValueChange={(value: string) =>
            updateRouteOverview(route.id, value).catch((e) => {
              showToast("An error occurred updating the route overview.");
            })
          }
          placeholder="Enter an overview for your route."
          defaultValue={route.overview}
        ></TextInput>
      </Section>
      <div className="w-[500px]">
        <UsageChart routeId={routeId} />
      </div>
    </div>
  ) : null;
}
