import React, { ReactNode, useEffect, useState } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export enum Orientation {
  VERTICAL,
  HORIZONTAL,
}

export function SortableItem(props: any) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {props.renderItem(props.id)}
    </div>
  );
}

export function DragAndDropList({
  defaultValue,
  renderItem,
  onOrderChange,
  orientation = Orientation.VERTICAL,
}: {
  defaultValue?: Array<string>;
  renderItem: (id: string) => ReactNode;
  onOrderChange: (ids: Array<string>) => void;
  orientation?: Orientation;
}) {
  const [items, setItems] = useState<Array<string>>(defaultValue || []);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event: any) {
    const { active, over } = event;
    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        const result = arrayMove(items, oldIndex, newIndex);
        onOrderChange(result);
        return result;
      });
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={items}
        strategy={
          orientation === Orientation.VERTICAL
            ? verticalListSortingStrategy
            : horizontalListSortingStrategy
        }
      >
        {items.map((id) => (
          <SortableItem key={id} id={id} renderItem={renderItem} />
        ))}
      </SortableContext>
    </DndContext>
  );
}
