import { useId, useState } from "react";
import Spinner from "./Spinner";

type MediaType = "image" | "audio" | "video";

interface DisplayContentProps {
  mediaTypes: MediaType[];
  busy: boolean;
}

interface DropzoneProps {
  onMedia: (url: string, contentType: string) => Promise<any>;
  mediaTypes?: MediaType[];
}

function processImage(file: File): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        if (!ctx) {
          reject(new Error("Failed to get canvas context."));
          return;
        }
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        const mimeType = file.type;
        if (
          !mimeType ||
          (mimeType != "image/jpeg" && mimeType != "image/png")
        ) {
          reject(new Error("Invalid file type."));
        }
        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error("Unable to process image."));
            }
          },
          mimeType,
          0.8
        );
      };
      img.src = e.target!!.result as string;
    };

    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}

function convertBlobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64DataURL = reader.result as string;
      resolve(base64DataURL);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

function fileToBase64(file: File): Promise<string> {
  if (file.type.startsWith("image/")) {
    return processImage(file).then(convertBlobToBase64);
  } else {
    return convertBlobToBase64(file);
  }
}

const DisplayContent: React.FC<DisplayContentProps> = ({
  mediaTypes,
  busy,
}) => {
  const displayText = `Click to upload or drag and drop`;
  const fileTypeText = mediaTypes.map((type) => type.toUpperCase()).join(", ");

  return (
    <>
      {busy ? (
        <div className="flex flex-col justify-center items-center">
          {/* Spinner component assumed to be defined elsewhere */}
          <p className="mb-2 text-md text-gray-500 dark:text-gray-400">
            <span className="font-semibold">Uploading...</span>
          </p>
        </div>
      ) : (
        <>
          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
            <span className="font-semibold">{displayText}</span>
          </p>
          <p className="text-xs text-gray-500 dark:text-gray-400">
            {fileTypeText}
          </p>
        </>
      )}
    </>
  );
};

const Dropzone: React.FC<DropzoneProps> = ({
  onMedia,
  mediaTypes = ["image"],
}) => {
  const [busy, setBusy] = useState(false);
  const dropzoneId = useId();

  const acceptedMediaTypes: Record<MediaType, string> = {
    image: "image/*",
    audio: "audio/*",
    video: "video/*",
  };

  const handleFile = (file: File) => {
    const fileType = file.type.split("/")[0] as MediaType;
    if (!busy && mediaTypes.includes(fileType)) {
      setBusy(true);
      fileToBase64(file)
        .then((base64DataURL) => onMedia(base64DataURL, file.type))
        .catch((error) => console.error(error))
        .finally(() => setBusy(false));
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      handleFile(files[0]);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const items = event.dataTransfer.items;
    if (items) {
      Array.from(items).forEach((item) => {
        const file = item.getAsFile();
        if (file) {
          handleFile(file);
        }
      });
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const computeAccepts = () => {
    return mediaTypes.map((type) => acceptedMediaTypes[type]).join(",");
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      className="flex items-center justify-center w-full h-full"
    >
      <label
        htmlFor={dropzoneId}
        className="p-4 flex flex-col items-center justify-center h-full w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-gray-200"
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          <DisplayContent mediaTypes={mediaTypes} busy={busy} />
          <input
            disabled={busy}
            id={dropzoneId}
            type="file"
            className="hidden"
            accept={computeAccepts()}
            onChange={handleFileChange}
          />
        </div>
      </label>
    </div>
  );
};

export default Dropzone;
