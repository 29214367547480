import { useCallback, useEffect, useState } from "react";
import { IRoute, IStop, useCurrentUser } from "../api";
import { Theme } from "@radix-ui/themes";
import { ConfirmationDialogProvider } from "../components/ConfirmationDialog";
import { ToastProvider } from "../components/Toast";
import AppBar from "../components/AppBar";
import MapSection from "../editor/MapSection";
import RouteSection from "../editor/RouteSection";
import DetailsSection from "../editor/DetailsSection";
import { useNavigate } from "react-router-dom";

interface DetailSelection {
  routeId?: string;
  stopId?: string;
}

const useCheckLoggedOut = () => {
  const user = useCurrentUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (user === null) {
      navigate("/auth");
    }
  }, [user, navigate]);
  return user;
};

export default function Console() {
  const [selectedRoute, setSelectedRoute] = useState<IRoute | undefined>();
  const [selectedDetails, setSelectedDetails] = useState<
    DetailSelection | undefined
  >();
  const handleRouteChange = useCallback((route: IRoute) => {
    setSelectedRoute(route);
    setSelectedDetails({ routeId: route.id });
  }, []);
  const handleSelectRouteDetails = useCallback((route: IRoute) => {
    setSelectedDetails({ routeId: route.id });
  }, []);
  const handleStopChange = useCallback((stop: IStop) => {
    setSelectedDetails({ stopId: stop.id });
  }, []);
  const user = useCheckLoggedOut();

  if (user === null || user === undefined) {
    return null;
  }

  return (
    <Theme panelBackground="solid" radius="large">
      <ConfirmationDialogProvider>
        <ToastProvider>
          <div className="w-full h-full flex flex-row space-x-2 p-2 pt-16 relative">
            <div className="absolute top-0 left-0 w-full h-16">
              <AppBar />
            </div>
            <div className="w-1/3 xl:w-1/5 flex flex-col space-y-2">
              {selectedRoute ? (
                <MapSection
                  onSelectStop={(stopId) => {
                    setSelectedDetails({ stopId });
                  }}
                  route={selectedRoute}
                ></MapSection>
              ) : null}
              <RouteSection
                selected={selectedDetails}
                onSelectRoute={handleRouteChange}
                onSelectRouteDetails={handleSelectRouteDetails}
                onSelectStop={handleStopChange}
              ></RouteSection>
            </div>
            <div className="w-2/3 xl:w-4/5">
              <DetailsSection
                onDeleteStop={function (): void {
                  setSelectedDetails({});
                }}
                onDeleteRoute={function (): void {
                  setSelectedDetails({});
                }}
                {...selectedDetails}
              ></DetailsSection>
            </div>
          </div>
        </ToastProvider>
      </ConfirmationDialogProvider>
    </Theme>
  );
}
