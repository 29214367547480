import mapboxgl from "mapbox-gl";
import { FC, ReactNode, useEffect, useRef, useState } from "react";

import React, { createContext, useContext } from "react";

const MapContext = createContext<any>(undefined);

export interface LngLat {
  lng: number;
  lat: number;
}

export interface ClickEvent {
  lngLat: LngLat;
}

export interface MapMarkerProps {
  lngLat: LngLat;
  color?: string;
  onClick?: () => void;
}

export interface MapProps {
  children?: ReactNode;
}

export const MapMarker: React.FC<MapMarkerProps> = ({
  lngLat,
  onClick,
  color = "teal",
}) => {
  const map = useContext(MapContext);
  useEffect(() => {
    if (map) {
      const mbMarker = new mapboxgl.Marker({ color })
        .setLngLat([lngLat.lng, lngLat.lat])
        .addTo(map);
      const element = mbMarker.getElement();
      element.style.cursor = "pointer";
      if (onClick) {
        element.addEventListener("click", () => onClick());
      }
      return () => {
        mbMarker.remove();
      };
    }
  }, [lngLat, map, color]);

  return <></>;
};

const useMap = (elementId: string): any => {
  const [map, setMap] = useState();

  useEffect(() => {
    const mapInstance = new mapboxgl.Map({
      container: elementId,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [-75.695, 45.424721],
      zoom: 9,
    });

    mapInstance.on("load", () => setMap(mapInstance));

    return () => mapInstance.remove();
  }, [elementId]);

  return map;
};

export const Map: FC<MapProps> = ({ children }) => {
  const elementId = useRef<string>(crypto.randomUUID().toString()).current;
  const map = useMap(elementId);

  return (
    <MapContext.Provider value={map}>
      <div className="w-full h-full">
        <div id={elementId} className="w-full h-full" />
        {children}
      </div>
    </MapContext.Provider>
  );
};
