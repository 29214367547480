import React, { useState } from "react";
import { ProfileDialog } from "./ProfileDialog";
import { PersonIcon } from "@radix-ui/react-icons";

const AppBar: React.FC = ({}) => {
  const [showProfile, setShowProfile] = useState(false);
  const handleShowProfile = () => {
    setShowProfile(true);
  };
  return (
    <div className="bg-green-900 text-white p-4 flex justify-between items-center shadow-sm">
      <span className="text-lg font-bold">Pintales</span>

      <button
        onClick={handleShowProfile}
        className="text-gray-200 hover:opacity-80 transition-all"
      >
        <PersonIcon className="w-5 h-5" />
      </button>
      <ProfileDialog onClose={() => setShowProfile(false)} open={showProfile} />
    </div>
  );
};

export default AppBar;
