import { Button, Card, Select, Separator, Text } from "@radix-ui/themes";
import {
  IRoute,
  IStop,
  addRoute,
  addStop,
  updateStopIndex,
  useRoutes,
  useStop,
  useStops,
} from "../api";
import { ReactNode, useCallback, useRef, useState } from "react";
import { DragHandleHorizontalIcon, PlusIcon } from "@radix-ui/react-icons";
import { DragAndDropList } from "../components/DragAndDropList";
import { useToast } from "../components/Toast";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import Spinner from "../components/Spinner";

function CardButton({
  selected,
  onClick,
  children,
}: {
  selected?: boolean;
  children?: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) {
  return (
    <Card
      style={selected ? { backgroundColor: "rgba(0,0,0,0.10)" } : {}}
      className={
        "w-full py-3 px-4 cursor-pointer hover:bg-gray-100 transition-all"
      }
      onClick={onClick}
    >
      {children}
    </Card>
  );
}

function Stop({
  selected,
  stopId,
  onClick,
}: {
  selected?: boolean;
  stopId: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) {
  const [isDragging, setIsDragging] = useState(false);
  const stop = useStop(stopId);
  return (
    <CardButton selected={selected} onClick={onClick}>
      <div className="flex flex-row justify-between items-center">
        <Text>{stop?.title}</Text>
        <DragHandleHorizontalIcon
          onMouseDown={() => {
            setIsDragging(true);
            window.addEventListener("mouseup", () => setIsDragging(false), {
              once: true,
            });
          }}
          className={`w-5 h-5 text-gray-400 cursor-${
            isDragging ? "grabbing" : "grab"
          }`}
        ></DragHandleHorizontalIcon>
      </div>
    </CardButton>
  );
}

function Selector({ onRoute }: { onRoute: (route: IRoute) => void }) {
  const routes = useRoutes();
  return routes ? (
    <Select.Root
      onValueChange={(routeId) => {
        if (routes) {
          onRoute(routes.filter((route) => route.id === routeId)[0]);
        }
      }}
      size="3"
    >
      <Select.Trigger
        variant="surface"
        radius="large"
        className="w-full"
        placeholder="Select a route..."
      />
      <Select.Content>
        <Select.Group>
          <Select.Label>Routes</Select.Label>
          {routes?.map((route) => {
            return (
              <Select.Item key={route.id} value={route.id}>
                {route.title}
              </Select.Item>
            );
          })}
        </Select.Group>
      </Select.Content>
    </Select.Root>
  ) : null;
}

function AddStopButton({ onAddStop }: { onAddStop: () => Promise<any> }) {
  const [busy, setBusy] = useState(false);

  const handleClick = async () => {
    if (!busy) {
      try {
        setBusy(true);
        await onAddStop();
      } finally {
        setBusy(false);
      }
    }
  };
  return (
    <CardButton onClick={() => handleClick()}>
      <div className="flex flex-row justify-between items-center">
        <Text>Add a Stop</Text>
        {busy ? (
          <Spinner size="md" theme="light" />
        ) : (
          <PlusIcon className={`w-5 h-5 text-gray-400`}></PlusIcon>
        )}
      </div>
    </CardButton>
  );
}

function RouteConfig({
  route,
  selected,
  onSelectRouteDetails,
  onSelectStop,
  onAddStop,
}: {
  route: IRoute;
  selected?: any;
  onSelectRouteDetails: (route: IRoute) => void;
  onSelectStop: (stop: IStop) => void;
  onAddStop: () => Promise<any>;
}) {
  const stops = useStops(route.id);
  const stopIds = stops?.map((stop) => stop.id);
  const stopForId = useCallback(
    (stopId: string) => {
      return stops?.find((stop) => stop.id === stopId);
    },
    [stops, stopIds]
  );
  const handleOrderChange = (ids: Array<string>) => {
    ids.forEach((id, index) => {
      try {
        updateStopIndex(id, index);
      } catch (e) {
        console.error(e);
      }
    });
  };
  return (
    <div>
      <div className="flex flex-col space-y-4 w-full">
        <CardButton
          selected={selected?.routeId === route.id}
          onClick={() => {
            onSelectRouteDetails(route);
          }}
        >
          <Text>Route Overview</Text>
        </CardButton>
        <Separator size="4" />
      </div>
      {stopIds && stopIds.length > 0 ? (
        <div className={"mt-4 flex flex-col space-y-4 w-full"}>
          <DragAndDropList
            key={stopIds.length}
            defaultValue={stopIds}
            onOrderChange={handleOrderChange}
            renderItem={(stopId) => (
              <Stop
                selected={selected?.stopId === stopId}
                onClick={() => {
                  const stop = stopForId(stopId);
                  if (stop) {
                    onSelectStop(stop);
                  }
                }}
                stopId={stopId}
              />
            )}
          ></DragAndDropList>
        </div>
      ) : null}
      <div className="mt-4">
        <AddStopButton onAddStop={onAddStop}></AddStopButton>
      </div>
    </div>
  );
}

const RouteOptionsMenu = () => {
  const { showToast } = useToast();
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button className="flex flex-col items-center justify-center p-2 rounded hover:bg-gray-200">
          <span className="block h-1 w-1 bg-gray-700 rounded-full mb-1"></span>
          <span className="block h-1 w-1 bg-gray-700 rounded-full mb-1"></span>
          <span className="block h-1 w-1 bg-gray-700 rounded-full"></span>
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className="p-2 shadow-lg rounded bg-white">
        <DropdownMenu.Item
          className="p-2 hover:bg-gray-100 cursor-pointer"
          onSelect={() => {
            addRoute().catch((e) =>
              showToast("An error occurred adding the route.")
            );
          }}
        >
          Add Route
        </DropdownMenu.Item>
        {/* <DropdownMenu.Item
          className="p-2 hover:bg-gray-100 cursor-pointer"
          onSelect={() => console.log("Add Route From KML")}
        >
          Add Route From KML
        </DropdownMenu.Item> */}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default function RouteSection({
  onSelectRoute,
  onSelectRouteDetails,
  onSelectStop,
  selected,
}: {
  selected?: any;
  onSelectRouteDetails: (route: IRoute) => void;
  onSelectRoute: (route: IRoute) => void;
  onSelectStop: (stop: IStop) => void;
}) {
  const [route, setRoute] = useState<IRoute | undefined>();
  const { showToast } = useToast();
  const handleSelectRoute = useCallback(
    (route: IRoute) => {
      setRoute(route);
      onSelectRoute(route);
    },
    [setRoute, onSelectRoute]
  );
  const handleAddStop = () => {
    if (route) {
      return addStop(route.id).catch((e) =>
        showToast("An error occurred adding the stop.")
      );
    } else {
      return Promise.resolve();
    }
  };
  const handleKmlParsed = function (data: any): void {
    // const { route, stops} = data;
  };

  const asyncAction = async () => {
    // Perform your async operation here
    await new Promise((resolve) => setTimeout(resolve, 2000));
  };

  return (
    <div className="p-2 flex flex-col w-full space-y-4 flex-1 overflow-y-scroll">
      <div className="flex flex-row space-x-2 items-center">
        <div className="w-[90%]">
          <Selector onRoute={handleSelectRoute}></Selector>
        </div>
        <div className="w-[20px]">
          <RouteOptionsMenu />
        </div>
      </div>
      {route ? (
        <RouteConfig
          selected={selected}
          route={route}
          onAddStop={handleAddStop}
          onSelectStop={onSelectStop}
          onSelectRouteDetails={onSelectRouteDetails}
        />
      ) : null}
    </div>
  );
}
