import React, { useState, useEffect, useRef, useCallback } from "react";
import { Chart, registerables } from "chart.js";
import { getRouteUsageData } from "../api";

Chart.register(...registerables);

interface UsageData {
  month: string;
  totalLoads: number;
  uniqueUsers: number;
}

interface Props {
  routeId: string;
}

const UsageChart: React.FC<Props> = ({ routeId }) => {
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const chartRef = useRef<Chart | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const fetchData = useCallback(
    async (year: number) => {
      try {
        const data = await getRouteUsageData(routeId, year);
        return data?.map((item: any, index: number) => ({
          month: new Date(year, index, 1).toLocaleString("default", {
            month: "long",
          }),
          totalLoads: item.totalLoads,
          uniqueUsers: item.uniqueUsers,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
        return [];
      }
    },
    [routeId]
  );

  useEffect(() => {
    const initChart = async () => {
      const data = await fetchData(selectedYear);

      if (chartRef.current) {
        chartRef.current.destroy();
      }

      const ctx = canvasRef.current?.getContext("2d");
      if (ctx) {
        chartRef.current = new Chart(ctx, {
          type: "bar",
          data: {
            labels: data.map((d: any) => d.month),
            datasets: [
              {
                label: "Total Route Loads",
                data: data.map((d: any) => d.totalLoads),
                backgroundColor: "rgba(54, 162, 235, 0.5)",
                borderColor: "rgba(54, 162, 235, 1)",
                borderWidth: 1,
              },
              {
                label: "Unique Users",
                data: data.map((d: any) => d.uniqueUsers),
                backgroundColor: "rgba(255, 206, 86, 0.5)",
                borderColor: "rgba(255, 206, 86, 1)",
                borderWidth: 1,
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  // Ensure only whole numbers are displayed
                  stepSize: 1,
                  callback: function (value) {
                    if ((value as number) % 1 === 0) {
                      return value;
                    }
                  },
                },
                // Automatically calculate a suggested max value with padding
                suggestedMax:
                  Math.max(
                    ...data.map((d: any) =>
                      Math.max(d.totalLoads, d.uniqueUsers)
                    )
                  ) * 1.1,
              },
            },
          },
        });
      }
    };

    initChart();
  }, [selectedYear, fetchData]);

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(parseInt(event.target.value, 10));
  };

  const currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let year = 2023; year <= currentYear; year++) {
    yearOptions.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }

  return (
    <div>
      <select onChange={handleYearChange} value={selectedYear}>
        {yearOptions}
      </select>
      <canvas ref={canvasRef} id="usageChart"></canvas>
    </div>
  );
};

export default UsageChart;
