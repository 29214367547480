import React, {
  useEffect,
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from "react";

interface ToastContextProps {
  showToast: (
    message: string,
    type?: "info" | "error",
    duration?: number
  ) => void;
}

interface ToastProviderProps {
  children: ReactNode;
}

const ToastContext = createContext<ToastContextProps>({
  showToast: () => {},
});

interface ToastProps {
  message: string;
  onClose: () => void;
  type?: "info" | "error";
}

export const Toast: React.FC<ToastProps> = ({
  message,
  onClose,
  type = "info",
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000); // Auto-close after 3 seconds

    return () => clearTimeout(timer);
  }, [onClose]);

  const backgroundColor = type === "error" ? "bg-red-600" : "bg-blue-600";
  const hoverColor =
    type === "error" ? "hover:bg-red-700" : "hover:bg-blue-700";

  return (
    <div
      className={`${backgroundColor} ${hoverColor} fixed top-4 right-4 min-w-0 rounded-lg shadow-lg overflow-hidden flex items-center text-white text-sm px-4 py-3`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      {/* Icon based on the type */}
      {type === "error" && (
        <svg
          className="w-6 h-6 mr-2"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          {/* Error Icon */}
          <path d="M18.364 5.636a9 9 0 11-12.728 0 9 9 0 0112.728 0z"></path>
          <path d="M12 8v4"></path>
          <path d="M12 16h.01"></path>
        </svg>
      )}
      {/* Add more icons for different types as needed */}
      <span className="flex-grow">{message}</span>
      <button
        onClick={onClose}
        className="ml-4 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-800 focus:ring-white"
      >
        <svg
          className="w-4 h-4"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          {/* Close Icon */}
          <path d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>
    </div>
  );
};

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toast, setToast] = useState<{
    message: string;
    type: "info" | "error";
  } | null>(null);

  const showToast = useCallback(
    (
      message: string,
      type: "info" | "error" = "error",
      duration: number = 3000
    ) => {
      setToast({ message, type });
      setTimeout(() => setToast(null), duration);
    },
    []
  );

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
