import mapboxgl from "mapbox-gl";
import { useEffect, useRef, useState } from "react";
import * as turf from "@turf/turf";

export interface LngLat {
  lng: number;
  lat: number;
}

export interface ClickEvent {
  lngLat: LngLat;
}

export interface Marker {
  id: string;
  lngLat: LngLat;
  icon?: string;
  radius?: number;
}

const clickHandlers: Array<(event: ClickEvent) => void> = [];
let mapInstance: any = undefined;

const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoidGhpcnR5cmVwb3J0aW5jIiwiYSI6ImNsY2I1OThyajIxdDgzc21vYzV2YXd5bDYifQ.AiLicVQWfW9TIjScDzuieA";

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

export function addClickHandler(clickHandler: (event: ClickEvent) => void) {
  clickHandlers.push(clickHandler);
  if (mapInstance) {
    mapInstance.getCanvas().style.cursor = "crosshair";
  }
  return true;
}

export function removeClickHandler(clickHandler: (event: ClickEvent) => void) {
  const index = clickHandlers.indexOf(clickHandler);
  if (index >= 0) {
    clickHandlers.splice(index, 1);
    return true;
  }
  return false;
}

const useMap = (elementId: string) => {
  const [map, setMap] = useState();
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: elementId,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [-75.695, 45.424721],
      zoom: 9,
    });
    map.on("click", (event: ClickEvent) => {
      clickHandlers.forEach((clickHandler) => clickHandler(event));
      clickHandlers.splice(0, clickHandlers.length);
      mapInstance.getCanvas().style.cursor = "grab";
    });
    map.on("load", () => {
      setMap(map);
      mapInstance = map;
    });
  }, [elementId]);
  return map;
};

function createMarkerEl(marker: any) {
  if (marker.icon) {
    const el = document.createElement("div");
    el.className = "marker";
    el.style.backgroundImage = `url(${marker.icon})`;
    el.style.width = "30px";
    el.style.height = "30px";
    //el.style.borderRadius = "100%";
    el.style.backgroundSize = "100%";
    return new mapboxgl.Marker(el);
  } else {
    return new mapboxgl.Marker();
  }
}

/**
 * Adds a translucent circle around a point on a Mapbox map.
 *
 * @param {LngLat} center - The center of the circle [lng, lat].
 * @param {number} radiusInMeters - The radius of the circle in meters.
 * @param {mapboxgl.Map} map - The Mapbox map instance to add the circle to.
 * @param {string} circleId - A unique ID for the circle layer and source.
 */
function addStopRadius(
  center: LngLat,
  radiusInMeters: number,
  map: any,
  circleId: string
) {
  // Generate a circle Feature with Turf.js
  const circleFeature = turf.circle(
    [center.lng, center.lat],
    radiusInMeters / 1000,
    {
      steps: 120,
      units: "kilometers",
    }
  );

  // Add a new source for the circle to the map
  map.addSource(circleId, {
    type: "geojson",
    data: circleFeature,
  });

  // Add a new layer to the map for the circle
  map.addLayer({
    id: circleId,
    type: "fill",
    source: circleId,
    paint: {
      "fill-color": "#0000ff",
      "fill-opacity": 0.25,
    },
  });

  // Return a function to remove the circle from the map
  return () => {
    if (map.getLayer(circleId)) {
      try {
        map.removeLayer(circleId);
      } catch (e) {
        console.error(e);
      }
    }
    if (map.getSource(circleId)) {
      try {
        map.removeSource(circleId);
      } catch (e) {
        console.error(e);
      }
    }
  };
}

function MapMarker({
  map,
  marker,
  onClick,
}: {
  map: any;
  marker: Marker;
  onClick?: (marker: Marker) => void;
}) {
  useEffect(() => {
    const mbMarker = createMarkerEl(marker)
      .setLngLat([marker.lngLat.lng, marker.lngLat.lat])
      .addTo(map);

    const element = mbMarker.getElement();
    element.style.cursor = "pointer";

    if (onClick) {
      element.addEventListener("click", () => onClick(marker));
    }

    const removeStopRadius = marker.radius
      ? addStopRadius(marker.lngLat, marker.radius, map, marker.id)
      : null;

    return () => {
      mbMarker.remove();
      if (removeStopRadius) removeStopRadius();
    };
  }, [marker, map, onClick]);

  return null;
}

function useFitToBounds(map: any, markers: Array<Marker> | undefined) {
  useEffect(() => {
    try {
      if (map && markers) {
        const bounds = markers
          .map((marker) => marker.lngLat)
          .map(({ lng, lat }) => [lng, lat]);

        if (bounds && bounds.length > 0) {
          if (bounds.length === 1) {
            map.fitBounds(bounds.concat(bounds), { padding: 100 });
          } else {
            const containsPair = (array: any, pair: number[]) =>
              array.some(
                ([lng, lat]: [lng: number, lat: number]) =>
                  lng === pair[0] && lat === pair[1]
              );

            const uniqueBounds = bounds.reduce((acc, pair: any) => {
              if (!containsPair(acc, pair)) {
                acc.push(pair);
              }
              return acc;
            }, []);
            map.fitBounds(uniqueBounds, { padding: 100 });
          }
        }
      }
    } catch (e) {
      console.error("Error occurred updating bounds", e, markers);
    }
  }, [map, markers?.length]);
}

export default function Mapbox({
  markers,
  onClickMarker,
}: {
  markers: Array<Marker>;
  onClickMarker: (marker: Marker) => void;
}) {
  const elementId = useRef(crypto.randomUUID().toString()).current;
  const map: any = useMap(elementId);
  useFitToBounds(map, markers);
  return (
    <div className="w-full h-full" id={elementId}>
      {map
        ? markers.map((marker) => {
            return (
              <MapMarker
                key={marker.id}
                map={map}
                marker={marker}
                onClick={() => {
                  if (onClickMarker) {
                    onClickMarker(marker);
                  }
                  map.panTo(marker.lngLat);
                }}
              ></MapMarker>
            );
          })
        : null}
    </div>
  );
}
