import { Card } from "@radix-ui/themes";
import StopDetails from "./StopDetails";
import RouteDetails from "./RouteDetails";

export default function DetailsSection({
  stopId,
  routeId,
  onDeleteStop,
  onDeleteRoute,
}: {
  stopId?: string;
  routeId?: string;
  onDeleteStop: () => void;
  onDeleteRoute: () => void;
}) {
  return routeId || stopId ? (
    <div className="w-full h-full p-2">
      <Card className="w-full h-full">
        <div className="h-full overflow-y-scroll">
          {stopId ? (
            <StopDetails
              onDelete={onDeleteStop}
              key={stopId}
              stopId={stopId}
            ></StopDetails>
          ) : routeId ? (
            <RouteDetails
              onDelete={onDeleteRoute}
              key={routeId}
              routeId={routeId}
            ></RouteDetails>
          ) : null}
        </div>
      </Card>
    </div>
  ) : null;
}
