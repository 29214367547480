import React, { createContext, useContext, useState, ReactNode } from "react";
import { Button, Dialog } from "@radix-ui/themes";

export type DialogButtonType = "ok" | "danger" | "cancel";

interface DialogButton {
  type: DialogButtonType;
  label: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface ConfirmationDialogProps {
  title: string;
  message: string;
  buttons: DialogButton[];
  onClose: () => void;
}

interface ConfirmationDialogContextType {
  openDialog: (props: Omit<ConfirmationDialogProps, "onClose">) => void;
  closeDialog: () => void;
}

interface ConfirmationDialogProviderProps {
  children?: ReactNode;
}

const ConfirmationDialogContext = createContext<ConfirmationDialogContextType>({
  openDialog: () => {},
  closeDialog: () => {},
});

export const useConfirmationDialog = () =>
  useContext(ConfirmationDialogContext);

export const ConfirmationDialogProvider: React.FC<
  ConfirmationDialogProviderProps
> = ({ children }) => {
  const [dialogProps, setDialogProps] =
    useState<ConfirmationDialogProps | null>(null);

  const openDialog = (props: Omit<ConfirmationDialogProps, "onClose">) => {
    setDialogProps({ ...props, onClose: closeDialog });
  };

  const closeDialog = () => {
    setDialogProps(null);
  };

  return (
    <ConfirmationDialogContext.Provider value={{ openDialog, closeDialog }}>
      {children}
      {dialogProps && <ConfirmationDialog {...dialogProps} />}
    </ConfirmationDialogContext.Provider>
  );
};

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  message,
  buttons,
  onClose,
}) => {
  const getButtonStyles = (type: "ok" | "danger" | "cancel") => {
    switch (type) {
      case "ok":
        return {};
      case "danger":
        return { backgroundColor: "red", color: "white" };
      case "cancel":
        return {
          backgroundColor: "white",
          color: "black",
          border: "1px solid black",
        };
      default:
        return {};
    }
  };

  return (
    <Dialog.Root open={true} onOpenChange={(open) => !open && onClose()}>
      <Dialog.Content style={{ maxWidth: "90vh" }}>
        <Dialog.Title>{title}</Dialog.Title>
        <Dialog.Description>{message}</Dialog.Description>
        <div className="flex justify-end space-x-2 mt-4">
          {buttons.map((button, index) => (
            <Button
              key={index}
              onClick={(event) => {
                if (button.onClick) button.onClick(event);
                onClose();
              }}
              style={getButtonStyles(button.type)}
            >
              {button.label}
            </Button>
          ))}
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};
