import React, { useEffect, useState } from "react";
import {
  createUser,
  sendResetPasswordEmail,
  signIn,
  useCurrentUser,
} from "../api";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";

interface SignUpProps {
  setError: React.Dispatch<React.SetStateAction<string>>;
}

interface SignInProps {
  setError: React.Dispatch<React.SetStateAction<string>>;
}

const isEmailValid = (email: string) => {
  return /\S+@\S+\.\S+/.test(email);
};

const AgreementText: React.FC = () => {
  return (
    <p className="text-sm text-gray-600 mt-4 text-center">
      By signing up, you agree to our{" "}
      <a
        href="https://app.getterms.io/view/4l4ak/tos/en-us"
        className="text-blue-600 hover:text-blue-800"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Service
      </a>
      ,{" "}
      <a
        href="https://app.getterms.io/view/4l4ak/privacy/en-us"
        className="text-blue-600 hover:text-blue-800"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
      , and{" "}
      <a
        href="https://app.getterms.io/view/4l4ak/aup/en-us"
        className="text-blue-600 hover:text-blue-800"
        target="_blank"
        rel="noopener noreferrer"
      >
        Acceptable Use Policy
      </a>
      .
    </p>
  );
};

const SignUp: React.FC<SignUpProps> = ({ setError }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const updateEmail = (value: string) => {
    setEmail(value);
    setError("");
  };

  const updatePassword = (value: string) => {
    setPassword(value);
    setError("");
  };

  const updateConfirmPassword = (value: string) => {
    setConfirmPassword(value);
    setError("");
  };

  const isFormValid = () => {
    return (
      isEmailValid(email) &&
      password.length >= 8 &&
      password === confirmPassword
    );
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError("");
    setLoading(true);

    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    try {
      await createUser(email, password);
      console.log("Sign up successful");
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          Email
        </label>
        <input
          type="email"
          id="email"
          required
          value={email}
          onChange={(e) => updateEmail(e.target.value)}
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      <div>
        <label
          htmlFor="password"
          className="block text-sm font-medium text-gray-700"
        >
          Password
        </label>
        <input
          type="password"
          id="password"
          required
          value={password}
          onChange={(e) => updatePassword(e.target.value)}
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      <div>
        <label
          htmlFor="confirmPassword"
          className="block text-sm font-medium text-gray-700"
        >
          Confirm Password
        </label>
        <input
          type="password"
          id="confirmPassword"
          required
          value={confirmPassword}
          onChange={(e) => updateConfirmPassword(e.target.value)}
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      <div>
        <button
          type="submit"
          disabled={!isFormValid() || loading}
          className={`flex justify-center items-center w-full py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
            isFormValid() ? "bg-blue-600 hover:bg-blue-700" : "bg-gray-500"
          }`}
        >
          {loading ? <Spinner theme={"light"} /> : "Sign Up"}
        </button>
      </div>
      <AgreementText />
    </form>
  );
};

interface PasswordResetProps {
  goBack: () => void;
}

const PasswordReset: React.FC<PasswordResetProps> = ({ goBack }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isEmailValid(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      setLoading(true);
      await sendResetPasswordEmail(email);
      setError("Password reset email sent! Check your inbox.");
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const isFormValid = () => {
    return isEmailValid(email);
  };

  const isEnabled = isFormValid() && !loading;

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          Email
        </label>
        <input
          type="email"
          id="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      {error && <p className="text-sm text-red-600">{error}</p>}
      <div>
        <button
          type="submit"
          disabled={!isEnabled}
          className={`w-full py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
            isEnabled ? "bg-blue-600 hover:bg-blue-700" : "bg-gray-600"
          }`}
        >
          Send password reset email
        </button>
      </div>
      <div className="mt-4 text-center">
        <button
          type="button"
          onClick={() => goBack()}
          className="text-blue-600 hover:text-blue-800"
        >
          Back to Sign In
        </button>
      </div>
    </form>
  );
};

const SignIn: React.FC<SignInProps> = ({ setError }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);

  const updateEmail = (value: string) => {
    setEmail(value);
    setError("");
  };

  const updatePassword = (value: string) => {
    setPassword(value);
    setError("");
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError("");
    setLoading(true);

    try {
      await signIn(email, password);
      console.log("Sign in successful");
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const isFormValid = () => {
    return isEmailValid(email);
  };

  const isEnabled = isFormValid();

  return resetPassword ? (
    <PasswordReset goBack={() => setResetPassword(false)} />
  ) : (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          Email
        </label>
        <input
          type="email"
          id="email"
          required
          value={email}
          onChange={(e) => updateEmail(e.target.value)}
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      <div>
        <label
          htmlFor="password"
          className="block text-sm font-medium text-gray-700"
        >
          Password
        </label>
        <input
          type="password"
          id="password"
          required
          value={password}
          onChange={(e) => updatePassword(e.target.value)}
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      <div>
        <button
          type="submit"
          disabled={!isEnabled}
          className={`w-full py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
            isEnabled ? "bg-blue-600 hover:bg-blue-700" : "bg-gray-600"
          }`}
        >
          {loading ? <Spinner theme={"light"} /> : "Sign In"}
        </button>
      </div>

      <div className="mt-4 text-center">
        <button
          type="button"
          onClick={() => setResetPassword(true)}
          className="text-blue-600 hover:text-blue-800"
        >
          Forgot your password?
        </button>
      </div>
    </form>
  );
};

const useCheckLoggedIn = () => {
  const user = useCurrentUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      navigate("/builder");
    }
  }, [user, navigate]);
  return user;
};

const LoginPage = () => {
  const [isSignUp, setIsSignUp] = useState(true);
  const [error, setError] = useState("");
  return (
    <div className="flex-1 flex items-center justify-center h-screen bg-gray-100">
      <div className="max-w-md w-full bg-white p-8 border border-gray-300 rounded-lg">
        <h1 className="text-center text-3xl font-bold text-gray-900 mb-4">
          Welcome to Pintales
        </h1>
        <h2 className="text-center text-2xl font-extrabold text-gray-900">
          {isSignUp ? "Sign Up" : "Sign In"}
        </h2>
        {isSignUp ? (
          <SignUp setError={setError} />
        ) : (
          <SignIn setError={setError} />
        )}
        {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
        <div className="mt-4 text-center">
          <button
            type="button"
            onClick={() => setIsSignUp(!isSignUp)}
            className="text-blue-600 hover:text-blue-800"
          >
            {isSignUp
              ? "Already have an account? Sign In"
              : "Don't have an account? Sign Up"}
          </button>
        </div>
      </div>
    </div>
  );
};

const Auth: React.FC = () => {
  const user = useCheckLoggedIn();

  if (user === undefined) {
    return null;
  }

  return (
    <div className="flex min-h-screen flex-col md:flex-row">
      <div className="md:w-1/2 bg-green-900 flex flex-col items-center justify-center text-white p-10 hidden md:flex">
        <img src="/logo.png" alt="Logo" className="mb-8 w-20" />
        <h1 className="text-4xl font-bold mb-4">Pintales</h1>
        <p className="text-lg mt-2">
          CURATED, SELF-GUIDED NATURE HIKES AND URBAN TOURS.
        </p>
      </div>

      <div className="flex-1 bg-white flex justify-center items-center">
        <LoginPage />
      </div>
    </div>
  );
};
export default Auth;
