import { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { User } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import {
  DocumentSnapshot,
  QuerySnapshot,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getFirestore,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app);

/// Utilities

interface Indexed {
  index: number;
}

function sortByIndex(arr: Array<any>): Array<any> {
  return [...arr].sort((a: any, b: any) => {
    if (a.index < b.index) {
      return -1;
    } else if (a.index > b.index) {
      return 1;
    } else {
      return 0;
    }
  });
}

function queryString(o: any) {
  return Object.entries(o)
    .map(([k, v]) => {
      return k + "=" + v;
    })
    .sort()
    .join("&");
}

let debounces: { [id: string]: NodeJS.Timeout } = {};

function debounce(
  f: () => Promise<any>,
  id: string,
  timeoutMs: number
): Promise<void> {
  return new Promise((resolve, reject) => {
    const existing = debounces[id];
    if (existing) {
      clearTimeout(existing);
    }
    debounces[id] = setTimeout(() => {
      f().then(resolve).catch(reject);
    }, timeoutMs);
  });
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

async function updateProperty(
  entityType: string,
  entityId: string,
  fieldName: string,
  value: any
) {
  const functionName = `update${capitalizeFirstLetter(
    entityType
  )}${capitalizeFirstLetter(fieldName)}`;
  return httpsCallable(
    functions,
    functionName
  )({
    [`${entityType}Id`]: entityId,
    [fieldName]: value,
  });
}

async function debounceUpdateProperty(
  entityType: string,
  entityId: string,
  fieldName: string,
  value: any
) {
  await debounce(
    () => updateProperty(entityType, entityId, fieldName, value),
    `${entityType}.${entityId}.${fieldName}`,
    300
  );
}

function useHttpsCallableResult(functionName: string, args: any) {
  const [result, setResult] = useState<any>(undefined);

  useEffect(() => {
    const run = async () => {
      const f = httpsCallable(functions, functionName);

      try {
        const result: any = await f(args);
        setResult(result.data);
      } catch (error) {
        console.error("Execution failed: ", functionName, args, error);
        setResult(undefined);
      }
    };

    run();
  }, [queryString(args)]);

  return result;
}

/// Auth

export async function createUser(email: string, password: string) {
  await createUserWithEmailAndPassword(getAuth(), email, password);
}

export async function signIn(email: string, password: string) {
  await signInWithEmailAndPassword(getAuth(), email, password);
}

export async function sendResetPasswordEmail(email: string) {
  await sendPasswordResetEmail(getAuth(), email);
}

export function currentUser() {
  return getAuth().currentUser;
}

export function currentUid() {
  return currentUser()?.uid;
}

export function signOut() {
  return getAuth().signOut();
}

export function useCurrentUser() {
  const [currentUser, setCurrentUser] = useState<User | null | undefined>(
    undefined
  );

  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  return currentUser;
}

/// Firestore

const db = getFirestore(app);

export async function generateId(table: string) {
  const doc = await addDoc(collection(db, table), { owner: currentUid() });
  return doc.id;
}

function useDocs(table: string, filterMap: any) {
  const [result, setResult] = useState<any>();
  useEffect(() => {
    const q = query(
      collection(db, table),
      ...Object.entries(filterMap).map(([key, value]: [string, any]) =>
        where(key, "==", value)
      )
    );
    const unsub = onSnapshot(q, (snapshot: QuerySnapshot) => {
      setResult(
        snapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        })
      );
    });
    return () => unsub();
  }, [table, queryString(filterMap)]);
  return result;
}

function useDoc(table: string, id: string) {
  const [result, setResult] = useState<any>();
  useEffect(() => {
    const q = doc(db, table, id);
    const unsub = onSnapshot(q, (doc: any) => {
      setResult({ id: doc.id, ...doc.data() });
    });
    return () => unsub();
  }, [table, id]);
  return result;
}

/// Routes

export enum RouteType {
  Trail = "trail",
  Tour = "tour",
}

export enum RouteVisibilityType {
  Public = "public",
  Hidden = "hidden",
}

export interface IRoute {
  id: string;
  type: RouteType;
  owner: string;
  live?: boolean;
  title?: string;
  visibility?: RouteVisibilityType;
  overview?: string;
}

export async function addRoute() {
  const createRouteFunction = httpsCallable(functions, "createRoute");
  const result = await createRouteFunction({
    type: "trail",
    live: false,
    title: "New Route",
    owner: currentUid(),
  });
  const data: any = result.data;
  return { id: data.id };
}

export async function deleteRoute(routeId: string) {
  await httpsCallable(
    functions,
    "deleteRoute"
  )({
    routeId,
  });
}

export async function updateRouteTitle(routeId: string, title: string) {
  await debounceUpdateProperty("route", routeId, "title", title);
}

export async function updateRouteType(routeId: string, type: RouteType) {
  await debounceUpdateProperty("route", routeId, "type", type);
}

export async function updateRouteLive(routeId: string, live: boolean) {
  await updateProperty("route", routeId, "live", live);
}

export async function updateRouteVisibility(
  routeId: string,
  visibility: string
) {
  await updateProperty("route", routeId, "visibility", visibility);
}

export async function updateRouteOverview(routeId: string, overview: string) {
  await debounceUpdateProperty("route", routeId, "overview", overview);
}

export function useRoutes(): Array<IRoute> | undefined {
  return useDocs("routes", { owner: currentUid() });
}

export function useRoute(id: string) {
  return useDoc("routes", id);
}

export async function getRouteUsageData(
  routeId: string,
  year: number
): Promise<any> {
  const getRouteUsageDataFunction = httpsCallable(
    functions,
    "getRouteUsageData"
  );
  const result = await getRouteUsageDataFunction({ routeId, year });
  return result.data;
}

/// Stops

export interface IStop {
  id: string;
  description?: string;
  icon?: string;
  latitude?: number;
  longitude?: number;
  owner: string;
  title?: string;
  routeId: string;
  index: number;
  alertRadius?: number;
}

export async function deleteStop(stopId: string) {
  await httpsCallable(
    functions,
    "deleteStop"
  )({
    stopId,
  });
}

export function useStops(routeId: string): Array<IStop> | undefined {
  const stops = useDocs("stops", { routeId, owner: currentUid() });
  if (stops) {
    return sortByIndex(stops);
  } else {
    return undefined;
  }
}

export function usePublicStops(routeId: string): IStop[] | undefined {
  return useHttpsCallableResult("getRouteStops", { routeId })?.stops;
}

export function useStop(id: string): IStop | undefined {
  return useDoc("stops", id);
}

export async function updateStopTitle(stopId: string, title: string) {
  await debounceUpdateProperty("stop", stopId, "title", title);
}

export async function updateStopDescription(
  stopId: string,
  description: string
) {
  await debounceUpdateProperty("stop", stopId, "description", description);
}

export async function updateStopAlertRadius(
  stopId: string,
  alertRadius: number
) {
  await debounceUpdateProperty("stop", stopId, "alertRadius", alertRadius);
}

export async function updateStopIndex(stopId: string, index: number) {
  await updateProperty("stop", stopId, "index", index);
}

export async function updateStopIcon(stopId: string, url: string) {
  const reader = new FileReader();
  const base64Data = url.split(",")[1];
  const updateStopIcon = httpsCallable(functions, "updateStopIcon");
  const result = await updateStopIcon({
    imageBuffer: base64Data,
    stopId,
  });
  return result.data;
}

export async function resetStopIcon(stopId: string) {
  const resetStopIcon = httpsCallable(functions, "resetStopIcon");
  const result = await resetStopIcon({
    stopId,
  });
  return result.data;
}

export async function updateStopLocation(
  stopId: string,
  latitude?: number | null,
  longitude?: number | null
) {
  if (!latitude && !longitude) return;
  let params: any = { stopId };
  if (latitude && !isNaN(latitude)) {
    params.latitude = latitude;
  }
  if (longitude && !isNaN(longitude)) {
    params.longitude = longitude;
  }
  await debounce(
    () => httpsCallable(functions, "updateStopLocation")(params),
    "updateStopLocation",
    300
  );
}

export async function updateStopLatitude(stopId: string, latitude: number) {
  await updateStopLocation(stopId, latitude, null);
}

export async function updateStopLongitude(stopId: string, longitude: number) {
  await updateStopLocation(stopId, null, longitude);
}

export async function addStop(routeId: string) {
  const createStopFunction = httpsCallable(functions, "createStop");
  const result = await createStopFunction({
    routeId,
    title: "New Stop",
  });
  const data: any = result.data;
  return { id: data.id };
}

/// Media

export interface IThumbnail {
  url: string;
  size: string;
}

export interface IStopMedia {
  id: string;
  owner: string;
  stopId: string;
  routeId: string;
  contentType: string;
  index: number;
  thumbnails: Array<IThumbnail>;
  url?: string;
  caption?: string;
}

export function useStopMedia(stopId: string): Array<IStopMedia> | undefined {
  const media = useDocs("media", { stopId, owner: currentUid() });
  if (media) {
    return sortByIndex(media);
  } else {
    return undefined;
  }
}

export function usePublicStopPreviewMedia(
  stopId: String
): Array<IStopMedia> | undefined {
  return useHttpsCallableResult("getStopMedia", { stopId })?.media;
}

export async function deleteStopMedia(mediaId: string) {
  await httpsCallable(
    functions,
    "deleteMedia"
  )({
    mediaId,
  });
}

export async function addStopMedia(stopId: string, dataUrl: string) {
  // Extract the content type and base64 data from the data URL
  const match = dataUrl.match(/^data:(.*?);base64,(.*)$/);
  if (!match) {
    throw new Error("Invalid data URL.");
  }
  const contentType = match[1];
  const base64Data = match[2];
  let result;
  if (contentType.startsWith("image/")) {
    const uploadImageFunction = httpsCallable(functions, "uploadImage");
    const result = await uploadImageFunction({
      imageBuffer: base64Data,
      stopId,
      contentType,
    });
    return result.data;
  } else if (contentType.startsWith("audio/")) {
    const uploadAudioFunction = httpsCallable(functions, "uploadAudio");
    const result = await uploadAudioFunction({
      audioBuffer: base64Data,
      stopId,
      contentType,
    });
    return result.data;
  } else {
    throw new Error("Unsupported media type.");
  }

  return result;
}

export async function updateMediaIndex(mediaId: string, index: number) {
  await updateProperty("media", mediaId, "index", index);
}

export async function updateMediaCaption(mediaId: string, caption: string) {
  await debounceUpdateProperty("media", mediaId, "caption", caption);
}

/// Profiles

export interface IProfile {
  id: string;
  bio?: string;
  website?: string;
  name?: number;
}

export function useProfile(id: string): IProfile {
  return useDoc("profiles", id) || { id };
}

export async function updateProfileName(profileId: string, name: string) {
  await debounceUpdateProperty("profile", profileId, "name", name);
}

export async function updateProfileBio(profileId: string, bio: string) {
  await debounceUpdateProperty("profile", profileId, "bio", bio);
}

export async function updateProfileWebsite(profileId: string, website: string) {
  await debounceUpdateProperty("profile", profileId, "website", website);
}
