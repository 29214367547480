/**
 * Checks if a given URL is a valid HTTPS URL.
 *
 * @param {string} urlString - The URL string to validate.
 * @return {boolean} True if the URL is valid and starts with "https://", false otherwise.
 */
export function isValidHttpsUrl(urlString: string): boolean {
  try {
    const url = new URL(urlString);
    return url.protocol === "https:";
  } catch (err) {
    return false;
  }
}
