import React, { useEffect, useState } from "react";
import { Button, TextArea, TextField } from "@radix-ui/themes";
import * as Dialog from "@radix-ui/react-dialog";
import {
  IProfile,
  updateProfileName,
  signOut,
  useProfile,
  currentUid,
  updateProfileBio,
  updateProfileWebsite,
} from "../api";
import { useToast } from "./Toast";
import { useConfirmationDialog } from "./ConfirmationDialog";
import BusyButton from "./BusyButton";
import { isValidHttpsUrl } from "../utils/validation";

interface ProfileDialogProps {
  open: boolean;
  onClose?: () => void;
}

function NameInput({ profile }: { profile: IProfile }) {
  const { showToast } = useToast();
  return (
    <label>
      Name
      <TextField.Root className="mt-1">
        <TextField.Input
          onChange={(event: any) => {
            updateProfileName(profile.id, event.nativeEvent.target.value).catch(
              () => showToast("An error occurred updating the name.")
            );
          }}
          placeholder="name"
          defaultValue={profile.name}
        />
      </TextField.Root>
    </label>
  );
}

function BioInput({ profile }: { profile: IProfile }) {
  const { showToast } = useToast();
  return (
    <label>
      Bio
      <div className="h-[200px]">
        <TextArea
          size="3"
          className="h-full"
          placeholder={"bio"}
          defaultValue={profile.bio}
          onChange={(event: any) =>
            updateProfileBio(profile.id, event.nativeEvent.target.value).catch(
              () => showToast("An error occurred updating the bio.")
            )
          }
        />
      </div>
    </label>
  );
}

interface WebsiteInputProps {
  profile: IProfile;
}

const WebsiteInput: React.FC<WebsiteInputProps> = ({ profile }) => {
  const { showToast } = useToast();
  const [website, setWebsite] = useState(profile.website || "");
  const [error, setError] = useState("");

  useEffect(() => {
    setWebsite(profile.website || "");
  }, [profile.website]);

  const handleWebsiteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newWebsite = event.target.value;
    setWebsite(newWebsite);

    if (newWebsite && !isValidHttpsUrl(newWebsite)) {
      setError("Please enter a valid website URL.");
    } else {
      setError("");
      if (isValidHttpsUrl(newWebsite)) {
        updateProfileWebsite(profile.id, newWebsite).catch(() =>
          showToast("An error occurred updating the website.")
        );
      }
    }
  };

  return (
    <label>
      Website
      <TextField.Root className="mt-1">
        <TextField.Input
          value={website}
          onChange={handleWebsiteChange}
          placeholder="website"
        />
      </TextField.Root>
      {error && <p className="text-red-600 text-sm mt-1">{error}</p>}
    </label>
  );
};

export const ProfileDialog: React.FC<ProfileDialogProps> = ({
  open,
  onClose,
}) => {
  const uid = currentUid()!;
  const profile = useProfile(uid);
  const [name, setName] = useState(profile.name);
  const [bio, setBio] = useState(profile.bio);
  const [website, setWebsite] = useState(profile.website);
  const { openDialog } = useConfirmationDialog();
  const handleLogout = async () => {
    openDialog({
      title: "Logout",
      message: "Are you sure you want to logout?",
      buttons: [
        {
          type: "cancel",
          label: "Cancel",
        },
        {
          type: "ok",
          label: "Logout",
          onClick: async (event) => {
            signOut();
          },
        },
      ],
    });
  };

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(open) => {
        if (!open && onClose) {
          onClose();
        }
      }}
    >
      <Dialog.Overlay
        style={{ zIndex: 1000 }}
        className="fixed inset-0 bg-black bg-opacity-30"
      />
      <Dialog.Content
        style={{ zIndex: 1001 }}
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 rounded-lg w-1/2 max-w-md text-gray-800"
      >
        <Dialog.Title>Edit Profile</Dialog.Title>
        <div className="flex flex-col space-y-4">
          <NameInput profile={profile} />
          <BioInput profile={profile} />
          <WebsiteInput profile={profile} />
          <div className="flex flex-row justify-end">
            <BusyButton onClick={handleLogout} type={"primary"}>
              Logout
            </BusyButton>
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};
